import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const permissionSlice = createSlice({
  name: 'permission',
  initialState: { permissions: null } as { permissions: null | { [permCode: string]: boolean }},
  reducers: {
    setPermission(state, { payload }: PayloadAction<string[]>) {
      state.permissions = payload.reduce((a, { name }: any) => {
        a[name] = true;
        return a;
      }, {} as { [permCode: string]: boolean });

      localStorage.setItem('permission', JSON.stringify(payload));
    },
    clearPermission(state) {
      state.permissions = null;
    }
  }
});

export const { setPermission, clearPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
