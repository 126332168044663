import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './slices/session.slice';
import permissionReducer from './slices/permission.slice';

const store = configureStore({ 
  reducer: {
    session: sessionReducer,
    permission: permissionReducer
  }
});

export default store;
