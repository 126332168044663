import { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { login, logout } from './app/store/slices/session.slice';
import { clearPermission, setPermission } from './app/store/slices/permission.slice';
import AxiosInstance from './app/singletons/AxiosInstance';
import LoadingPage from './app/pages/LoadingPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './assets/scss/global.scss';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';

const LoginPage = lazy(() => import('./app/pages/LoginPage'));
const DashboardPage = lazy(() => import('./app/pages/DashboardPage'));
const ChangePasswordPage = lazy(() => import('./app/pages/ChangePasswordPage'));

setupIonicReact({
  mode: 'md',
  loadingSpinner: 'dots'
});

const App: FunctionComponent = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    (document.body.style as any).zoom = '70%';

    const now: number = new Date().getTime();
    const exp: number = Number(localStorage.getItem('exp'));
    const diff: number = isNaN(exp)? 0 : ((exp - now) / 86400000);

    if (diff <= 1) {
      dispatch(logout());
      dispatch(clearPermission());
      
      if (window.location.pathname !== '/login') window.location.href = '/login';
      return;
    } 

    dispatch(login(JSON.parse(localStorage.getItem('user')!)));
    dispatch(setPermission(JSON.parse(localStorage.getItem('permission')!)));
    AxiosInstance.setToken('Bearer ' + localStorage.getItem('token'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <IonApp>
      <Suspense fallback={ <LoadingPage/> }>
        <IonReactRouter>
          <IonRouterOutlet className="d-flex flex-column min-vh-100">
            <Switch>
              <Route path="/login" component={ LoginPage }></Route>
              <Route path="/change-password" component={ ChangePasswordPage }></Route>
              <Route path="/*" component={ DashboardPage }></Route>
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </Suspense>
    </IonApp>
  );
};

export default App;
