import { IonSpinner } from "@ionic/react";

const LoadingPage = () => {
  
  return (
    <main className="w-100 h-100 d-flex align-items-center justify-content-center">
      <IonSpinner style={{ width: '50px', height: '50px' }}></IonSpinner>
    </main>
  );
};

export default LoadingPage;
