import axios, { AxiosRequestConfig } from 'axios';
import { environment } from '../../environments/environment';

class AxiosInstance {
    
  protected static token: string = '';

  public static setToken(token: string): void{ AxiosInstance.token = token; };

  public static getToken(): string{ return AxiosInstance.token; };

  public static get<T>(url: string, signal?: AbortSignal): Promise<T>{
    return axios.get<T>(`${ environment.apiUrl }/${ url }`, AxiosInstance.generateHeaders(signal)).then(({ data }) => data);
  };

  public static delete<T>(url: string, signal?: AbortSignal): Promise<T>{
    return axios.delete<T>(`${ environment.apiUrl }/${ url }`, AxiosInstance.generateHeaders(signal)).then(({ data }) => data);
  };

  public static put<T>(url: string, data: any, signal?: AbortSignal): Promise<T>{
    return axios.put<T>(`${ environment.apiUrl }/${ url }`, data, AxiosInstance.generateHeaders(signal)).then(({ data }) => data);
  };

  public static post<T>(url: string, data: any, signal?: AbortSignal): Promise<T>{
    return axios.post<T>(`${ environment.apiUrl }/${ url }`, data, AxiosInstance.generateHeaders(signal)).then(({ data }) => data);
  };

  protected static generateHeaders(signal?: AbortSignal): AxiosRequestConfig<any>{
    return {
      signal,
      headers: {
        accept: 'application/json',
        authorization: AxiosInstance.token
      }
    };
  };
};

export default AxiosInstance;
