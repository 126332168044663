import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserContainerModel } from '../../models/UserContainerModel';
import { SessionState } from '../states/session.state';
import AxiosInstance from '../../singletons/AxiosInstance';

const sessionSlice = createSlice({
  name: 'session',
  initialState: { isLogined: false, user: null } as SessionState,
  reducers: {
    login(state, { payload }: PayloadAction<UserContainerModel>) {
      state.user = payload;
      state.isLogined = true;

      localStorage.setItem('user', JSON.stringify(payload));
    },
    logout(state) {
      localStorage.clear();
      AxiosInstance.setToken('');
      
      state.user = null;
      state.isLogined = false;
    }
  }
});

export const { login, logout } = sessionSlice.actions;

export default sessionSlice.reducer;
